export default {
  siteTitle: 'Get bonus',
  blogerName: 'CASTRO',
  socialsList: [
    {
      name: 'telegram',
      url: 'https://t.me/+V-zVf93vKIY1NWZh'
    },
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@Castro8_/featured'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/allisoncastro0ff/'
    }
  ],
  projects: [
    {
      name: 'monro',
      url: 'https://monrocasino.life/c20f5c42c',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c086b8e0a',
      gameTitle: 'STARDA QUEEN (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/ce73277be',
      gameTitle: 'IZZI ART (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c93817b5d',
      gameTitle: 'LEGZO PUNK (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/c76c041eb',
      gameTitle: 'Doors Of Fresh (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c9d135a99',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c99a9b51d',
      gameTitle: 'Jet Air (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>CASTRO08</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'CASTRO08',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'brazil'
};
